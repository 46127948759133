.action-menu {
  display: flex;
  gap: 5px;
}

.action-menu .btn {
  min-width: 20px;
  white-space: nowrap;
}

.selection-column {
  width: 5px;
}
