@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

body {
  margin: 0;
  font-family: "open sans", sans-serif !important;
  font-size: 21px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-ycc-dark {
  color: rgb(252, 206, 17) !important;
  background-color: rgb(36, 35, 35);
}

.bg-ycc-mustard {
  background-color: rgb(148, 130, 27);
  color: white;
}

div.siteheader,
nav {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}

div.siteheader {
  font-size: 11px;
}

div.siteheader img.ycc-logo {
  max-height: 60px;
  margin: 5px 30px;
}

a.nav-link {
  color: white;
}

a.nav-link:hover {
  text-decoration: underline;
  color: rgb(252, 206, 17) !important;
}

.active a.nav-link {
  background-color: white;
  color: rgb(105, 93, 20);
}

a.dropdown-item {
  font-size: 13px;
}

.form-control.form-control-inline {
  width: auto;
  flex-grow: 1;
}

div.tool-tree-container {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  overflow-y: auto;
  max-height: 15rem;
}

.tool-tree,
.tool-tree ul {
  padding: 0;
  list-style: none;
}

.tool-tree li {
  padding-left: 1rem;
  margin-bottom: 0.25rem;
}
