@media print {
  .navbar {
    display: none;
  }
  .table-search {
    display: none;
    border-bottom: solid;
  }
  .ticket-button {
    display: none;
  }
  .rdt_TableCell {
    white-space: normal;
  }
  .rdt_TableCol {
    padding: 5px 10px;
    min-width: 50px;
    white-space: normal !important;
    word-wrap: break-word !important;
  }
  table {
    table-layout: auto;
  }

  .hide-printable {
    display: none;
  }

  .show-printable {
    display: block;
  }

  .report-data-printable {
    max-width: 10.75in;
    overflow: hidden;
  }

  div.report-data-printable div div div.row div.col-12 div.p-2 div,
  div.report-data-printable div div div div div {
    overflow: hidden hidden !important;
  }

  div.report-data-printable .summary-text {
    font-family: monospace;
  }
}

@media screen {
  .show-printable {
    display: none;
  }
}

.button-margin {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
