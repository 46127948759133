.job-card,
.ticket-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .p-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 16px;
  }
}

@media print {
  .p-2 {
    display: block;
    grid-template-columns: none;
    grid-gap: 0;
  }

  div.sd-inventory-container {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  div.sd-inventory-container div {
    width: auto!important;
  }
}

.search-input {
  width: 100%;
  box-sizing: border-box;
}

a.job-card-button {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 2.5rem;
  min-height: 2.5rem;
}


div.job-inv-card h2 {
  text-align: center;
  font-size: 20px;
}

div.job-inv-card h4 {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
}

table.job-inv-list {
  font-size: 85%;
}

td.job-inv-title {
  width: 30%;
}

td.job-inv-price {
  font-weight: 600;
}

div.sd-inventory-container {
  flex-wrap: wrap;
}

div.sd-inventory-container div {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
}

div.job-inv-title {
  margin-right: 10px;
  margin-left: auto;
  width: 45%;
}
